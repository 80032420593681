export const routes = {
  home: "/",
  articles: "/aktualności",
  fullArticle: "/artykuł/:title",
  gallery: "/galeria",
  galleryFlower: "/kwiaty",
  galleryHarvest: "/zbiory",
  statute: "/regulamin",
  informationManager: "/informacje",
  organizationManager: "/organizacja-działki",
  organizationElement: "/organizacja/:infoTitle",
  documentManager: "/dokumenty",
  documentElement: "dokument/:documentTitle",
  contact: "/kontakt",
};
